/* eslint-disable */
import React, { Component, Fragment } from "react";
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { Container, Col, Row, Card, CardBody, CardTitle, CardText, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, Form, FormGroup } from 'reactstrap';
import Select from 'react-select';
import StepTemplate from './StepTemplate';
import { getActionById, upsertAction, clearActionById } from '../../../redux/actions/jobAction';
import { getCurrentUser, hasRole } from '../../../factories/auth';

class StepAction extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            triggerid: 0,
            weekOption: [
                { value: 1, label: 'Mon' },
                { value: 2, label: 'Tue' },
                { value: 3, label: 'Wed' },
                { value: 4, label: 'Thu' },
                { value: 5, label: 'Fri' },
                { value: 6, label: 'Sat' },
                { value: 7, label: 'Sun' },
            ],
            eventName: null,
            selectedPeriod: null,
            customStyles: {}
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }
    }

    // ---------------------------------------------------------
    componentDidMount() {

        // this.props.getActionById(this.props.actionPassId);
        if (this.props.actionId > 0) {
            this.props.getActionById(this.props.actionId);
        }
        // this.props.getActionById(this.props.actionId);
    };
    componentWillUnmount() {

        this.props.clearActionById();
    }
    componentWillReceiveProps(nextProps) {


        if (nextProps.actionInfo && nextProps.actionInfo.length > 0 && nextProps.actionId > 0) {

            const actionInfo = nextProps.actionInfo[0];
            let weekday = [];
            if (!actionInfo) {
                return null;
            }
            if (actionInfo.weekday) {
                const infoWeekDay = JSON.parse(actionInfo.weekday);
                infoWeekDay.forEach((day, index) => {
                    let selectOption = this.state.weekOption.find((item, index) => (item.label === day));
                    weekday.push(selectOption);
                });


            }
            this.setState({
                eventName: actionInfo.actionname,
                description: actionInfo.actiondescription,
                triggerid: actionInfo.triggertype,
                selectedPeriod: actionInfo.weekday,
                triggerTime: actionInfo.actiontime,
                selectedPeriod: weekday,
            });
        }
    }
    // ---------------------------------------------------------

    handleTriggerChange = (e) => {
        let value = Number(e.target.value);
        this.setState({
            triggerid: value,
        });
    }

    handleInputChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePeriodsSelect = (value) => {


        this.setState({
            selectedPeriod: value,
        });
    }

    renderActionForm = () => {
        return (
            <Form>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="eventName" style={{ display: 'inline', width: "100%" }} >Event Name</Label>
                                <Input
                                    type="text"
                                    id="eventName"
                                    name="eventName"
                                    width="100%"
                                    value={this.state.eventName}
                                    onChange={this.handleInputChange}
                                    valid={this.state.eventName}
                                    invalid={!this.state.eventName}
                                    required={'required'}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label for="description" style={{ display: 'inline', width: "100%" }} >Description</Label>
                                <Input type="textarea" id="description" name="description" width="100%" value={this.state.description} onChange={this.handleInputChange} >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="trigger" style={{ display: 'inline', width: "100%" }} >Trigger</Label>
                                <Input type="select" id="trigger" name="trigger" width="100%" value={this.state.triggerid} onChange={this.handleTriggerChange}>
                                    <option value={0}>Event Trigger</option>
                                    <option value={1}>Time Trigger</option>
                                    <option value={3}>Dashboard Event</option>
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                {this.state.triggerid === 1 &&
                    <Row>
                        <Col md={12}>
                            <FormGroup row >

                                <Col md={3}>
                                    {/* select for event time */}
                                    <Label for="triggerPeriod" style={{ display: 'inline', width: "100%" }}>Time periods</Label>
                                    <Select
                                        isMulti
                                        name="triggerPeriod"
                                        options={this.state.weekOption}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={this.state.selectedPeriod}
                                        onChange={this.handlePeriodsSelect}
                                        styles={this.state.customStyles}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Label for="triggerTime" style={{ display: 'inline', width: "100%" }}>Time</Label>
                                    <Input
                                        type="time"
                                        name="triggerTime"
                                        id="fromTime"
                                        placeholder="time placeholder"
                                        value={this.state.triggerTime}
                                        onChange={this.handleInputChange}
                                    />
                                </Col>

                            </FormGroup>
                        </Col>
                    </Row>
                }
            </Form>
        );
    }
    updateActionInfo = () => {

    }

    handleBack = () => {
        this.props.onBack();
    }

    handleNext = () => {
        // if (this.updateActionInfo()) {
        //     this.props.handleNext();
        // }else{
        //     // todo alert notification;
        // };  
        // alert(this.state.triggerTime);   
        if (!this.validate()) {
            return;
        }
        if (sessionStorage.getItem('currentUserObj')) {
            let createDate = new Date().toISOString().split('.')[0]; //.replace('T', ' ');
            let weekday = [];
            let actiontime = '00:00:00';
            if (this.state.selectedPeriod) {
                this.state.selectedPeriod.forEach((item, index) => {
                    weekday.push(item.label);
                });
            }
            let timeRegex = /^\d{2}[:]\d{2}$/;
            if (this.state.triggerTime && timeRegex.test(this.state.triggerTime)) {


                actiontime = this.state.triggerTime + ':00';
            }

            try {
                let userObj = JSON.parse(sessionStorage.getItem('currentUserObj'));
                let actionObj = {
                    "actionid": this.props.actionPassId,
                    "actionname": this.state.eventName,
                    "actiondescription": this.state.description,
                    "triggertype": this.state.triggerid,
                    "weekday": JSON.stringify(weekday),
                    "actiontime": actiontime,
                    "createtime": createDate,
                    "createdby_user_id": userObj.userName,
                    "company_code": userObj.department,
                    "active": true,
                    "action": "Alert",
                }

                this.props.upsertAction(actionObj);

            } catch (e) {
                console.error(e);
            }
        }
    }

    validate = () => {
        if (!this.state.eventName || this.state.eventName.length === 0) {
            toast.error('Please input a event name.');
            return false;
        }

        if (this.state.triggerid === '1' && (!this.state.selectedPeriod || this.state.selectedPeriod.length === 0)) {
            toast.error('Please choose time.');
            return false;
        }

        return true;
    }

    render() {
        return (
            <StepTemplate
                classes={this.props.classes}
                canGoBack={this.props.canGoBack}
                canGoForward={this.props.canGoForward}
                onBack={this.handleBack}
                onNext={this.handleNext}
                onSkip={this.props.onNext}
                onCancel={this.props.onCancel}
                text={this.props.text}
            >
                {this.renderActionForm()}
            </StepTemplate>
        );
    }
}

// export default StepAction;

const mapStateToProps = (state) => {
    return state.job;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getActionById, upsertAction, clearActionById }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StepAction);