/* eslint-disable */
import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Col, Row, Button, Container, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
// import {Modal} from "react-overlays";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import { updateUser, modifyNvrPassword, getUserAssetclassList, upsertUserAssetclassList, getCompanyList, getRoles } from '../../../redux/actions/userAction';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import Select from 'react-select'

class UserEditFrom extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            data: [],
            email: '',
            fullName: '',
            userName: '',
            password: '',
            passwordConfirm: '',
            newUser: true,
            activeTab: '1',
            // groupdata: [],
            passConfirmed: true,
            // passconfirmValid: false,
            customStyles: {}
        };
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }

    }

    componentDidMount() {
        this.props.getCompanyList();
        this.props.getRoles();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.userObj === prevState.userObj) {
            return null
        }

        return ({
            loginName: nextProps.userObj.userName,
            fullName: nextProps.userObj.userName,
            email: nextProps.userObj.email,
            phoneNumber: nextProps.userObj.phoneNum,
            company: nextProps.userObj.companyCode,
            role: nextProps.userObj.role && nextProps.userObj.role.length > 0 ? nextProps.userObj.role[0] : null,
            password: '',
            passwordConfirm: '',
            userObj: nextProps.userObj,
        });
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     // alert(nextProps.modules.groupType);
    //     // alert('edit form');
    //     this.setState({
    //         loginName: nextProps.userObj.userId,
    //         fullName: nextProps.userObj.userName,
    //         email: nextProps.userObj.email,
    //         phoneNumber: nextProps.userObj.phoneNum,
    //         userObj: nextProps.userObj,
    //     });

    // }

    renderBackdrop = (props) => {
        return <div {...props} style={backdropStyle} />;
    };

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onCompanyChange = (selectedOption) => {
        this.setState({ company: selectedOption ? selectedOption.value : '' });
    };

    onRoleChange = (selectedOption) => {
        this.setState({ role: selectedOption ? selectedOption.value : '' });
    };

    onSubmit = () => {
        // if (this.props.userName !== '') {
        //     this.props.saveDetail({
        //         email: this.state.email,
        //         fullName: this.state.fullName,
        //         data: this.state.data,
        //         userName: this.state.loginName
        //     });
        // }
        // else {
        //     this.props.addDetail({
        //         email: this.state.email,
        //         fullName: this.state.fullName,
        //         data: this.state.data,
        //         userName: this.state.loginName
        //     });
        // }
        if (!sessionStorage.getItem('companyCode') || !sessionStorage.getItem('currentUser')) {
            toast.error('error in getting current user info');
            return;
        }
        if (this.validateForm()) {
            // alert ('v yes');
            let phoneNo = this.state.phoneNumber;
            if (phoneNo) {
                phoneNo = phoneNo.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
            }
            let userObj = {
                // userId: this.state.loginName,
                userName: this.state.loginName,
                email: this.state.email,
                phoneNum: phoneNo,
                companyCode: this.state.company ? this.state.company : sessionStorage.getItem('companyCode'),
                role: [this.state.role],
                // phoneNum: this.state.phoneNumber,
                // password: this.state.passwordConfirm,
            }
            let nvrSyncPwObj = {
                username: this.state.loginName,
            }

            if (this.state.passwordConfirm && this.state.passwordConfirm.length > 0 && this.state.password === this.state.passwordConfirm) {
                userObj.password = this.state.passwordConfirm;
                nvrSyncPwObj.newPass = this.state.passwordConfirm;
                this.props.modifyNvrPassword(nvrSyncPwObj)
            }
            // this.props.addUser(userObj,true);
            // this.props.updateUser(userObj, true);
            this.props.updateUser(userObj, true, this.props.isCurrent, this.props.updateUserCallBack);

            this.onClose();
        } else {
            alert('v no');
        }
    };

    onClose = () => {
        // this.setState({
        //     showModal: false
        // });
        // this.props.toggle();
        var currentLocation = window.location;
        currentLocation.pathname == "/pages/userprofile" ? history.back() : this.props.toggle();
    };

    validatePassConfirm = () => {
        let situation1 = (!this.state.password || this.state.password.length === 0) && (!this.state.passwordConfirm || this.state.passwordConfirm.length === 0);
        let situation2 = (this.state.passwordConfirm && this.state.passwordConfirm.length > 0 && this.state.password === this.state.passwordConfirm);
        // return (this.state.passwordConfirm && this.state.password === this.state.passwordConfirm);
        return (situation1 || situation2);
    }

    validateUserId = () => {
        return (this.state.loginName && this.state.loginName !== '');
    }

    validatePhoneNumber = () => {
        var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return (this.state.phoneNumber && regex.test(this.state.phoneNumber));
    }

    validateEmail = () => {
        // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
        // return  (this.state.email && this.state.email.match(regex) !== null);
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (this.state.email && regex.test(this.state.email));
    }

    validateForm = () => {
        // return this.validateUserId() && this.validatePhoneNumber() && this.validateEmail() && this.validatePassConfirm() ;
        return  /* this.validatePhoneNumber() && */ this.validateEmail() && this.validatePassConfirm();
    }

    render() {
        let labalStyle = { fontSize: '14px', textAlign: 'right' };
        const companyOptions = this.props.companyList.map(company => ({
            value: company.companyCode,
            label: company.companyName
        }));
        const roleOptions = this.props.roleList.map(role => ({
            value: role.name,
            label: role.description
        }));
        return (
            <div>
                <br />
                <Col md={12}>
                    {/* <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Login Name</span>
                            </Col>
                            <Col md={8}>
                                <span>User Name</span>
                                <Input
                                    name='loginName'
                                    placeholder='User Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.loginName}
                                    // disabled={this.props.userName !== ''}
                                    valid = {this.validateUserId()}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>User Name</span>
                            </Col>
                            <Col md={8}>
                                <span><Trans i18nKey="fullName">Full Name</Trans></span>
                                <Input
                                    name='fullName'
                                    placeholder='Full Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.fullName}
                                />
                            </Col>
                        </Row>
                        <br/> */}
                    <Row>
                        <Col md={2} style={labalStyle} >
                            <span>Phone Number</span>
                        </Col>
                        <Col md={8}>
                            {/* <span><Trans i18nKey="fullName">Full Name</Trans></span> */}
                            <Input
                                name='phoneNumber'
                                placeholder='Phone Number'
                                onChange={this.onInputChange}
                                // className="form-control"
                                value={this.state.phoneNumber}
                            // invalid={!this.validatePhoneNumber()}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={2} style={labalStyle} >
                            <span>Email</span>
                        </Col>
                        <Col md={8}>
                            <Input
                                name='email'
                                placeholder='Email'
                                onChange={this.onInputChange}
                                pattern={'^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'}
                                className="form-control"
                                value={this.state.email}
                                invalid={!this.validateEmail()}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={2} style={labalStyle} >
                            <span>Company</span>
                        </Col>
                        <Col md={8}>
                            {/* <Input
                                type='select'
                                name='company'
                                placeholder='Company'
                                onChange={this.onInputChange}
                                // className="fo rm-control"
                                value={this.state.company}
                                // valid = {this.validateEmail()}
                                disabled={false}
                                styles={this.state.customStyles}
                            >
                                {this.props.companyList.map((company, index) => (
                                    <option key={index} value={company.companyCode}>{company.companyName}</option>
                                ))}
                            </Input> */}
                            <Select
                                name='company'
                                placeholder='Company'
                                onChange={this.onCompanyChange}
                                value={companyOptions.find(option => option.value === this.state.company)}
                                options={companyOptions}
                                styles={this.state.customStyles}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={2} style={labalStyle} >
                            <span>Role</span>
                        </Col>
                        <Col md={8}>
                            {/* <Input
                                type='select'
                                name='role'
                                placeholder='Role'
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.role}
                                // valid = {this.validateEmail()}
                                disabled={false}
                            >
                                {this.props.roleList.map((role, index) => (
                                    <option key={role.id} value={role.name}>{role.description}</option>
                                ))} */}
                            {/* <option value={'SUPERADMIN'}>System Admin</option>
                                    <option value={'ADMIN'}>Company Admin</option>
                                    <option value={'USER'}>Company User</option> */}
                            {/* </Input> */}
                            <Select
                                name='role'
                                placeholder='Role'
                                onChange={this.onRoleChange}
                                value={roleOptions.find(option => option.value === this.state.role)}
                                options={roleOptions}
                                styles={this.state.customStyles}
                            />
                        </Col>
                    </Row>
                </Col>
                <br />
                <hr />
                <br />
                <Row>
                    <Col md={2} style={labalStyle} >
                        <span>Password</span>
                    </Col>
                    <Col md={8}>
                        <Input
                            //invalid = {!(this.state.passwordConfirm && this.state.password === this.state.passwordConfirm)}
                            name='password'
                            type='password'
                            onChange={this.onInputChange}
                            className="form-control"
                            value={this.state.password}
                            autoComplete='new-password'
                        // disabled={this.props.userName !== ''}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={2} style={labalStyle} >
                        <span>Password Confirmation</span>
                    </Col>
                    <Col md={8}>
                        <Input
                            // valid = {this.state.passConfirmed}
                            // valid = {this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                            // invalid = {this.state.passConfirmed}
                            invalid={this.state.passwordConfirm && this.state.password !== this.state.passwordConfirm}
                            name='passwordConfirm'
                            type='password'
                            onChange={this.onInputChange}
                            className="form-control"
                            value={this.state.passwordConfirm}
                            disabled={!this.state.password || this.state.password == ''}
                        // disabled = {true}
                        />
                    </Col>
                </Row>
                <br />
                <hr />
                <div class="d-flex justify-content-end">
                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()} >
                            Submit
                        </Button>}
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()} >
                            Submit
                        </Button>}
                    <Button outline color='secondary' type="reset" onClick={this.onClose} >
                        Close
                    </Button>
                </div>
            </div>

        )
    }
}


// export default UserEditFrom;

const mapStateToProps = (state) => {
    return state.user;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateUser, modifyNvrPassword, getUserAssetclassList, upsertUserAssetclassList, getCompanyList, getRoles }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditFrom);
